<template>
  <d-view-card
    ref="view"
    :filters="filters"
    :pagination="pagination"
    :fetch-function="rulesManagerController.getRules"
    :filter-transformer="clearFilters"
  >
    <template #default="{ filtersEntity, find, loading, data }">
      <div class="col-shrink row q-px-md q-pt-md">
        <q-card class="full-width">
          <q-card-section class="row q-col-gutter-sm">
            <d-user-select
              label="Agent"
              class="col-md-4 col-6"
              dense
              filled
              hide-bottom-space
              hide-hint
              v-model="filtersEntity.agentId"
              emit-value
              map-options
              :options="agentsDictionary"
              :multiple="false"
            />
            <q-select
              class="col-md-4 col-6"
              filled
              label="Status"
              clearable
              dense
              emit-value
              map-options
              v-model="filtersEntity.status"
              :options="statusDictionary"
            />
            <d-select-filtered
              class="col-md-4 col-12"
              filled
              label="Method"
              clearable
              dense
              emit-value
              map-options
              option-value="id"
              option-label="name"
              :options="methodsDictionary"
              v-model="filtersEntity.payoutMethodTypeId"
            />
          </q-card-section>
          <q-card-actions align="right">
            <q-btn
              :disable="loading"
              color="primary"
              label="Apply"
              @click="find"
            />
          </q-card-actions>
        </q-card>
      </div>
      <div class="col-grow">
        <div class="q-pa-md">
          <d-rules-table
            :items="data"
            @on-status="statusHandler"
            @on-pagination="onPaginationHandler"
          />
        </div>
      </div>
    </template>
  </d-view-card>
</template>

<script>
import { statusDictionary } from './config';
import { DRulesTable } from '@/features/rules-table';
import { DSelectFiltered } from '@/features/select-filtered';
import DUserSelect from '@/features/user-select';
import DViewCard from '@/layouts/view-card';
import {
  payoutMethodController,
  ruleManagerController,
  usersController,
} from '@/shared/api';
import { authUtils, notify, objectUtils } from '@/shared/utils';

export default {
  components: {
    DViewCard,
    DUserSelect,
    DRulesTable,
    DSelectFiltered,
  },
  data: () => ({
    filters: {
      agentId: null,
      status: null,
      payoutMethodTypeId: null,
    },
    statusDictionary,
    rulesManagerController: ruleManagerController(
      authUtils.getRoleByHierarchy()
    ),
    agentsDictionary: [],
    methodsDictionary: [],
    pagination: {
      lastId: 0,
      maxResults: 512,
      descending: true,
    },
  }),
  async mounted() {
    try {
      this.$refs.view.setLoading(true);
      const primaryRole = authUtils.getRoleByHierarchy();
      const [methodsResponse, agentsResponse] = await Promise.all([
        payoutMethodController(primaryRole).getPayoutMethods(),
        usersController(primaryRole).usersForFilter(),
      ]);
      this.methodsDictionary = methodsResponse.data;
      this.agentsDictionary = agentsResponse.data;
    } catch (e) {
      notify.error('Error loading directory');
    } finally {
      this.$refs.view.setLoading(false);
    }
  },
  methods: {
    async statusHandler(value, data) {
      try {
        this.$refs.view.setLoading(true);
        await this.rulesManagerController.updateRule({
          ...data,
          status: +value,
        });
        await this.$refs.view.find();
      } catch (e) {
        notify.error('Status update error');
      } finally {
        this.$refs.view.setLoading(false);
      }
    },
    async onPaginationHandler({ rowsPerPage }) {
      this.pagination.maxResults = rowsPerPage;
      await this.$refs.view.find();
    },
    clearFilters(filters) {
      let result = objectUtils.removeEmptyValuesRecursively(filters);
      if (filters.status === 0) {
        result = {
          ...result,
          status: 0,
        };
      }

      return result;
    },
  },
};
</script>
