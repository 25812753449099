export const columns = [
  {
    name: 'agents-rules',
    field: 'rules',
    label: 'Rules (%) / Agents',
    align: 'left',
  },
  {
    name: 'method',
    field: (row) => row.payoutMethodType.name,
    label: 'Method',
    align: 'left',
  },
  {
    name: 'status',
    field: 'status',
    label: 'Status',
    align: 'left',
  },
];
