export const statusDictionary = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Not active',
    value: 0,
  },
];
