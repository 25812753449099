<template>
  <d-virtual-table
    ref="rulesTable"
    :grid="grid"
    :columns="columns"
    :items="items"
    with-action-btns
    with-pagination
    @on-pagination="$emit('on-pagination', $event)"
  >
    <template v-slot:top>
      <q-space />
      <q-btn
        :class="btnClass(true)"
        icon="mdi-pencil-outline"
        color="primary"
        label="Create rule"
        :to="{ name: 'PayoutAssignmentCreation' }"
      />
    </template>
    <template v-slot:body-cell-agents-rules="{ props }">
      <q-td :props="props">
        <template v-for="rule in props.row.rules">
          <d-rule-chip :value="rule" :key="rule.id" />
        </template>
      </q-td>
    </template>
    <template v-slot:body-cell-status="{ props }">
      <q-td :props="props">
        <d-status-toggle
          :value="!!props.row.status"
          @input="$emit('on-status', $event, props.row)"
        />
      </q-td>
    </template>
    <template v-slot:body-cell-action-btns="{ props }">
      <q-td :props="props">
        <d-row-actions :item="props" page-name="PayoutAssignmentEdit" />
      </q-td>
    </template>
  </d-virtual-table>
</template>

<script>
import { columns } from './config';
import { DRuleChip, DStatusToggle } from './features';
import { DRowActions } from '@/features/row-actions';
import withActionTableClasses from '@/shared/mixins/withActionTableClasses';
import DVirtualTable from '@/shared/ui/virtual-table';

export default {
  components: {
    DVirtualTable,
    DRowActions,
    DRuleChip,
    DStatusToggle,
  },
  mixins: [withActionTableClasses],
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    columns,
  }),
};
</script>

<style scoped lang="scss">
.virtual-scroll-table {
  height: 51em;
}
</style>
