<template>
  <q-chip outline :color="value ? 'green' : 'red'">
    <q-toggle
      dense
      left-label
      :label="value ? 'Active' : 'Not active'"
      :value="value"
      checked-icon="mdi-check"
      color="green"
      unchecked-icon="mdi-close"
      @input="$emit('input', $event)"
    />
  </q-chip>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
