<template>
  <q-chip :dense="$q.screen.lt.md" outline color="primary">
    <q-avatar color="primary" text-color="white">
      {{ value.percent }}
    </q-avatar>
    {{ value.agentName }}
  </q-chip>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
